import * as THREE from "three";
import { ContentMode } from "./models/configuration";

class ProductNode extends THREE.Group {
  baseNode: THREE.Group | undefined;
  artworkMaterial: THREE.MeshBasicMaterial | undefined;
  isBordered = true;
  flipShadow: boolean | undefined;

  public constructor() {
    super();
  }

  public async setup() {}

  // Can be overridden with custom resizing logic if needed
  public setDimensions(width: number, height: number) {
    this.scale.x = width;
    this.scale.y = height;
  }

  // Can be overridden to account for frames etc if needed
  public totalSize() {
    return new THREE.Vector2(this.scale.x, this.scale.y);
  }

  public applyTextureContentModeTransformAndBorderShaderModifier(
    texture: THREE.Texture,
    targetDimensions: THREE.Vector2,
    borderThickness: THREE.Vector2,
    contentMode: ContentMode
  ) {
    const sourceDimensions = new THREE.Vector2(
      texture.image.naturalWidth,
      texture.image.naturalHeight
    );

    const sourceAspectRatio = sourceDimensions.x / sourceDimensions.y;
    const targetAspectRatio = targetDimensions.x / targetDimensions.y;

    // Note: If aspect ratio is equal then we can leave scale at default of {1, 1}
    let xScale = 1.0;
    let yScale = 1.0;
    if (contentMode === ContentMode.AspectScaleToFit) {
      if (sourceAspectRatio > targetAspectRatio) {
        yScale = targetAspectRatio / sourceAspectRatio;
      } else if (sourceAspectRatio < targetAspectRatio) {
        xScale = sourceAspectRatio / targetAspectRatio;
      }
    } else {
      if (sourceAspectRatio > targetAspectRatio) {
        xScale = sourceAspectRatio / targetAspectRatio;
      } else if (sourceAspectRatio < targetAspectRatio) {
        yScale = targetAspectRatio / sourceAspectRatio;
      }
    }
    texture.offset = new THREE.Vector2(
      (xScale - 1) / (2 * xScale),
      (yScale - 1) / (2 * yScale)
    );
    texture.repeat = new THREE.Vector2(1 / xScale, 1 / yScale);

    // Apply border with feathering effect to reduce aliasing
    // Note: Aliasing margin is hardcoded to a fixed distance which is not ideal when artwork is small in the scene...
    if (this.artworkMaterial) {
      this.artworkMaterial.onBeforeCompile = (shader) => {
        let token = "#include <map_fragment>";

        let borderX =
          targetDimensions.x <= 0 ? 0 : borderThickness.x / targetDimensions.x;
        let borderY =
          targetDimensions.y <= 0 ? 0 : borderThickness.y / targetDimensions.y;

        if (borderX > 0 || borderY > 0) {
          // Note: This is not the proper way to insert parameters into GLSL but it does the job. Some hacks required to make it work, e.g. toFixed(5) to ensure numbers have a decimal place so they're treated as floating points in GLSL
          let insert = /* glsl */ `
        float borderX = ${borderX.toFixed(5)};
        float borderY = ${borderY.toFixed(5)};

        if (vUv.x >= borderX && vUv.x <= (1.0 - borderX) && vUv.y >= borderY && vUv.y <=(1.0 - borderY)) {
          vec2 pos = vec2((-borderX + vUv.x) / (1.0 - 2.0 * borderX), (-borderY + vUv.y) / (1.0 - 2.0 * borderY));
          float fade = 0.0;
          float feather = 0.002;
          if (pos.y < feather) {
            fade = (feather - pos.y) / feather;
          } else if ((1.0 - pos.y) < feather) {
            fade = (feather - (1.0 - pos.y)) / feather;
          } else if (pos.x < feather) {
            fade = (feather - pos.x) / feather;
          } else if ((1.0 - pos.x) < feather) {
            fade = (feather - (1.0 - pos.x)) / feather;
          }
          vec4 col = texture2D(map, pos);
          diffuseColor = vec4(col.rgb * (1.0 - fade) + fade, col.a);
        } else {
          diffuseColor = vec4(0.95, 0.95, 0.95, 1);
        }
        `;

          shader.fragmentShader = shader.fragmentShader.replace(
            token,
            token + insert
          );
        }
      };

      this.artworkMaterial.customProgramCacheKey = () => {
        return `${targetDimensions.x}_${targetDimensions.y}_${borderThickness.x}_${borderThickness.y}`;
      };

      this.artworkMaterial.needsUpdate = true;
    }
  }
}

export default ProductNode;
